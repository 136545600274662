import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";



function Contact() {
  {
    
    /*
  const carDatas = [
    
  {
      name: "Nissan Serena White (2012)",
      price: "100",
      img: 'https://cars.japanesecartrade.com/vehicle_image/10152012_201306b.jpg',
      air: "7 seater",
      transmission: "Automatic",
      fuel: "2012",
    },

   {
      name: "Nissan Xtrail Silver (2015)",
      price: "90",
      img: 'https://imgcdn.zigwheels.my/large/gallery/color/30/284/nissan-x-trail-color-697008.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2015",
    },


    {
      name: "Nissan Xtrail White (2015)",
      price: "90",
      img: 'https://imgcdn.zigwheels.my/large/gallery/color/30/284/nissan-x-trail-color-271819.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2015",
    },

     {
      name: "Nissan Xtrail White (2012)",
      price: "90",
      img: 'https://carsales.pxcrush.net/general/car/cil/niss3179.jpg?width=618&height=412&watermark=1552494015',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2012",
    },

   

     {
      name: "Nissan Serena White (2013)",
      price: "100",
      img: 'https://cars.japanesecartrade.com/vehicle_image/10152012_201306b.jpg',
      air: "7 seater",
      transmission: "Automatic",
      fuel: "2013",
    },
  
   
   {
      name: "Nissan Juke Red (2011)",
      price: "90",
      img: 'https://vexstockimages.fastly.carvana.io/stockimages/2014_NISSAN_JUKE_SV%20SPORT%20UTILITY%204D_RED_stock_desktop_1920x1080.png?v=1645467943.198',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2011",
    },

    {
      name: "Nissan Juke Silver (2011)",
      price: "90",
      img: 'https://images.hgmsites.net/lrg/2011-nissan-juke-awd-5dr-wagon-i4-cvt-sv-angular-front-exterior-view_100328467_l.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2011",
    },

    {
      name: "Nissan Juke White (2013)",
      price: "90",
      img: 'https://ukr-prokat.com/wp-content/uploads/2019/02/Nissan-Juke.webp',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2013",
    },

 {
      name: "Nissan Xtrail Black (2013)-1st",
      price: "90",
      img: 'https://images.carexpert.com.au/vehicles/source-g/8/2/8210c24c.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2013",
    },


    {
      name: "Chevrolet Traverse Blue (2011)",
      price: "90",
      img: 'https://images.hgmsites.net/lrg/2011-chevrolet-traverse-fwd-4-door-ls-angular-front-exterior-view_100323058_l.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2011",
    },



    {
      name: "Nissan Xtrail Black (2013)-2nd",
      price: "90",
      img: 'https://images.carexpert.com.au/vehicles/source-g/8/2/8210c24c.jpg',
      air: "Suv",
      transmission: "Automatic",
      fuel: "2013",
    },

  
  ];

  const addCarsToFirestore = async () => {
    const carsCollection = collection(db, 'carsdata');
  
    for (const car of carDatas) {
      try {
        await addDoc(carsCollection, car);
        console.log(`Car ${car.name} added to Firestore`);
      } catch (error) {
        console.error(`Error adding car ${car.name} to Firestore:`, error);
      }
    }
  };
  
 
    useEffect(() => {
      addCarsToFirestore();
    }, []); // This will run the function only once on component mount
  
*/
  
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [question, setQuestion] = React.useState("");


  const sendEmail = () => {

    const templateParams = {
      type: 'Message',
    };
  
    emailjs
    .send('service_cogayf6', 'template_anduy23', templateParams, 'undyecVjIXyqVGZXU')
      .then(
        (response) => {
          console.log('Email sent successfully:', response.status, response.text);
        },
        (error) => {
          console.error('Failed to send message:', error.text);
        }
      );
  };



  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      sendEmail();
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Messages").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your message is sent! Please exit this page.");
  
      // Reload the page
     // window.location.reload();
      
      //console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };

  

  return (
    <>

     <Helmet>
        <title>
          Contact Us -  Dell's Rent A Car Eleuthera
        </title>

        <meta
          name="description"
          content="Get in touch with Dell's Car Rental in Hatchet Bay, Eleuthera, The Bahamas, for hassle-free car rental services. Conveniently located near North Eleuthera Airport (ELH) and Governor’s Harbour Airport (GHB), we offer easy access to reliable vehicles for all your travel needs. Contact us today to book your car or inquire about our services!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">


          <div className="row">
            <div className="col-md-6">
              <div className="contact-div">
                <div className="contact-div__text">
                  <a
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "green",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/HrGmUDEGgfbjReBz6"
                  >
                    Click For Google Maps Location
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel:+1242-551-0538"
                  >
                    &#x260E; +1242-551-0538
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel:+1242-815-9263"
                  >
                    &#x260E; +1242-815-9263
                  </a>
                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="mailto:  dellsrentacar@gmail.com"
                  >
                    &#9993; Click To Email Us
                  </a>
                  <a style={{ fontWeight: "bold", fontSize: "18px" }} href="/">
                    &#128205;   Hatchet Bay, Eleuthera, The Bahamas
                  </a>

                  
                  <br></br>
                  <h3>Need additional information?</h3>
                  <p>
                  Explore convenient and affordable vehicle rental in  Eleuthera, Bahamas with Dell's rent a car in Hatchet Bay. Our diverse fleet of well-maintained vehicles ensures reliable transportation solutions for your exploration needs of this beautiful region. Enjoy seamless travel experiences with Dell's rent a car – your trusted partner for exploring aroud Hatchet Bay, Eleuthera, Bahamas Book now at <a
                        href="https://maps.app.goo.gl/HrGmUDEGgfbjReBz6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        office
                      </a>    for an unmatched journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-div__form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postmsg();
                  }}
                >
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    Tell us about it <b>*</b>
                  </label>
                  <textarea
                    placeholder="Write Here.."
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>

                  <button type="submit" style={{backgroundColor: '#182b3a',
      backgroundImage: 'linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)', borderColor:'blue'}}   >
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:' #87CEEB'}}>+1242-551-0538</h3> 
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
